import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { Pipe } from "../components/Pipe";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Tabs" description="Accessible tabs component for React" mdxType="SEO" />
    <h1 {...{
      "id": "tabs"
    }}>{`Tabs`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#tabs-1" mdxType="TOCLink">Tabs</TOCLink>
    <TOCLink href="#tablist" mdxType="TOCLink">TabList</TOCLink>
    <TOCLink href="#tab" mdxType="TOCLink">Tab</TOCLink>
    <TOCLink href="#tabpanels" mdxType="TOCLink">TabPanels</TOCLink>
    <TOCLink href="#tabpanel" mdxType="TOCLink">TabPanel</TOCLink>
    <TOCLink href="#usetabscontext" mdxType="TOCLink">useTabsContext</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/tabs"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/tabs`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#tabpanel"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#tabpanel`}</a></li>
    </ul>
    <p>{`An accessible tabs component.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` and `}<inlineCode parentName="p">{`TabPanel`}</inlineCode>{` elements are associated by their order in the tree. None of the components are empty wrappers, each is associated with a real DOM element in the document, giving you maximum control over styling and composition.`}</p>
    <p>{`You can render any other elements you want inside of `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{`, but `}<inlineCode parentName="p">{`TabList`}</inlineCode>{` should only render `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` elements, and `}<inlineCode parentName="p">{`TabPanels`}</inlineCode>{` should only render `}<inlineCode parentName="p">{`TabPanel`}</inlineCode>{` elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Tabs>
      <TabList>
        <Tab>One</Tab>
        <Tab>Two</Tab>
        <Tab>Three</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <p>one!</p>
        </TabPanel>
        <TabPanel>
          <p>two!</p>
        </TabPanel>
        <TabPanel>
          <p>three!</p>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "#demos"
      }}>{`Check out the demos`}</a>{` for ideas on how to style and compose.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/tabs`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/tabs`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/tabs
# or
yarn add @reach/tabs
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "tabs-1"
    }}>{`Tabs`}</h3>
    <p>{`The parent component of the tab interface.`}</p>
    <h4 {...{
      "id": "tabs-css-selectors"
    }}>{`Tabs CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-tabs] {
}
`}</code></pre>
    <h4 {...{
      "id": "tabs-props"
    }}>{`Tabs Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"div"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-defaultindex"
            }}><inlineCode parentName="a">{`defaultIndex`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-index"
            }}><inlineCode parentName="a">{`index`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-keyboardactivation"
            }}><inlineCode parentName="a">{`keyboardActivation`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"auto"`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`"manual"`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"auto`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-orientation"
            }}><inlineCode parentName="a">{`orientation`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"horizontal"`}</inlineCode><Pipe mdxType="Pipe" /><inlineCode parentName="td">{`"vertical"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"horizontal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabs-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "tabs-as"
    }}>{`Tabs as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`Tabs will render a `}<inlineCode parentName="p">{`div`}</inlineCode>{` unless you specify a different element.`}</p>
    <h5 {...{
      "id": "tabs-children"
    }}>{`Tabs children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((props: { focusedIndex: number; id: string; selectedIndex: number }) => React.ReactNode)`}</inlineCode></p>
    <p>{`Tabs expects `}<inlineCode parentName="p">{`<TabList>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<TabPanels>`}</inlineCode>{` as children. The order doesn't matter; you can have tabs on the top or the bottom. You can have random elements inside as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Tabs>
      <div>Random</div>
      <TabPanels>
        <TabPanel>Uno</TabPanel>
        <TabPanel>Dos</TabPanel>
      </TabPanels>
      <TabList>
        <Tab>Uno</Tab>
        <Tab>Dos</Tab>
      </TabList>
    </Tabs>
  );
}
`}</code></pre>
    <p>{`You can also pass a render function to access data relevant to nested components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example() {
  return (
    <Tabs keyboardActivation="manual">
      {({ selectedIndex, focusedIndex }) => {
        let getTabStyle = (index) => ({
          borderBottom: \`4px solid \${
            selectedIndex === index
              ? "red"
              : focusedIndex === index
              ? "blue"
              : "black"
          }\`,
        });
        return (
          <React.Fragment>
            <TabList>
              <Tab style={getTabStyle(0)}>Uno</Tab>
              <Tab style={getTabStyle(1)}>Dos</Tab>
              <Tab style={getTabStyle(2)}>Tres</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>Uno</TabPanel>
              <TabPanel>Dos</TabPanel>
              <TabPanel>Tres</TabPanel>
            </TabPanels>
          </React.Fragment>
        );
      }}
    </Tabs>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "tabs-defaultindex"
    }}>{`Tabs defaultIndex`}</h5>
    <p><inlineCode parentName="p">{`defaultIndex?: number`}</inlineCode></p>
    <p>{`Starts the tabs at a specific index.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Tabs defaultIndex={1}>
      <TabPanels>
        <TabPanel>
          <img src="https://placekitten.com/400/200" alt="A picture of a cat" />
        </TabPanel>
        <TabPanel>
          <img
            src="https://www.placecage.com/400/200"
            alt="A picture of Nicolas Cage"
          />
        </TabPanel>
      </TabPanels>
      <TabList>
        <Tab>Kitten</Tab>
        <Tab>Cage</Tab>
      </TabList>
    </Tabs>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "tabs-index"
    }}>{`Tabs index`}</h5>
    <p><inlineCode parentName="p">{`index?: number`}</inlineCode></p>
    <p>{`Like form inputs, a tab's state can be controlled by the owner. Make sure to include an `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` as well, or else the tabs will not be interactive.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleSliderChange = (event) => {
    setTabIndex(parseInt(event.target.value, 10));
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <div>
      <input
        type="range"
        min="0"
        max="2"
        value={tabIndex}
        onChange={handleSliderChange}
      />

      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>One</Tab>
          <Tab>Two</Tab>
          <Tab>Three</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <p>Click the tabs or pull the slider around</p>
          </TabPanel>
          <TabPanel>
            <p>Yeah yeah. What's up?</p>
          </TabPanel>
          <TabPanel>
            <p>Oh, hello there.</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "tabs-keyboardactivation"
    }}>{`Tabs keyboardActivation`}</h5>
    <p><inlineCode parentName="p">{`keyboardActivation?: TabsKeyboardActivation`}</inlineCode></p>
    <p>{`Describes the activation mode when navigating a tablist with a keyboard. When set to `}<inlineCode parentName="p">{`"auto"`}</inlineCode>{` (`}<inlineCode parentName="p">{`TabsKeyboardActivation.Auto`}</inlineCode>{`), a tab panel is activated automatically when a tab is highlighted using arrow keys. When set to `}<inlineCode parentName="p">{`"manual"`}</inlineCode>{` (`}<inlineCode parentName="p">{`TabsKeyboardActivation.Manual`}</inlineCode>{`), the user must activate the tab panel with either the `}<kbd>{`Spacebar`}</kbd>{` or `}<kbd>{`Enter`}</kbd>{` keys. Defaults to `}<inlineCode parentName="p">{`"auto"`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE:`}</strong>{` TypeScript users should import and use the `}<inlineCode parentName="p">{`TabsKeyboardActivation`}</inlineCode>{` enum when used in strict mode.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { TabsKeyboardActivation } from "@reach/tabs";

function MyTabs() {
  return (
    <Tabs keyboardActivation={TabsKeyboardActivation.Manual}>{/* ... */}</Tabs>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "tabs-onchange"
    }}>{`Tabs onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?: (index: number) => void`}</inlineCode></p>
    <p>{`Calls back with the tab index whenever the user changes tabs, allowing your app to synchronize with it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  const colors = ["firebrick", "goldenrod", "dodgerblue"];
  const [tabIndex, setTabIndex] = React.useState(0);
  const backgroundColor = colors[tabIndex];
  return (
    <Tabs
      onChange={(index) => setTabIndex(index)}
      style={{
        color: "white",
        background: backgroundColor,
      }}
    >
      <TabList>
        <Tab>Red</Tab>
        <Tab>Yellow</Tab>
        <Tab>Blue</Tab>
      </TabList>
      <TabPanels style={{ padding: 20 }}>
        <TabPanel>The Primary Colors</TabPanel>
        <TabPanel>Are 1, 2, 3</TabPanel>
        <TabPanel>Red, yellow and blue.</TabPanel>
      </TabPanels>
    </Tabs>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "tabs-orientation"
    }}>{`Tabs orientation`}</h5>
    <p><inlineCode parentName="p">{`orientation?: TabsOrientation`}</inlineCode></p>
    <p>{`Allows you to switch the orientation of the tabs relative to their tab panels. This value can either be `}<inlineCode parentName="p">{`"horizontal"`}</inlineCode>{` (`}<inlineCode parentName="p">{`TabsOrientation.Horizontal`}</inlineCode>{`) or `}<inlineCode parentName="p">{`"vertical"`}</inlineCode>{` (`}<inlineCode parentName="p">{`TabsOrientation.Vertical`}</inlineCode>{`). Defaults to `}<inlineCode parentName="p">{`"horizontal"`}</inlineCode>{`.`}</p>
    <p>{`Changing the orientation will change how the arrow keys navigate between tabs. Arrow key navigation should logically follow the order in which tabs appear on the screen. For screen reader users, the `}<inlineCode parentName="p">{`aria-orientation`}</inlineCode>{` attribute provides the appropriate context to direct which keys should navigate to the next tab (this is provided automatically). As such, it's important to use this prop even if you have already styled your tabs for vertical layout.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  // Try changing the orientation!
  return (
    <Tabs orientation="vertical">
      <TabList>
        <Tab>One</Tab>
        <Tab>Two</Tab>
        <Tab>Three</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <p>one!</p>
        </TabPanel>
        <TabPanel>
          <p>two!</p>
        </TabPanel>
        <TabPanel>
          <p>three!</p>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE:`}</strong>{` TypeScript users should import and use the `}<inlineCode parentName="p">{`TabsOrientation`}</inlineCode>{` enum when used in strict mode.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { TabsOrientation } from "@reach/tabs";

function MyTabs() {
  return <Tabs orientation={TabsOrientation.Vertical}>{/* ... */}</Tabs>;
}
`}</code></pre>
    <h5 {...{
      "id": "tabs-div-props"
    }}>{`Tabs div props`}</h5>
    <p>{`All other props are passed to the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` (or another component passed to the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop).`}</p>
    <h3 {...{
      "id": "tablist"
    }}>{`TabList`}</h3>
    <p>{`The parent component of the tabs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabList>
  <Tab>Tacos</Tab>
  <Tab>Tortas</Tab>
</TabList>
`}</code></pre>
    <h4 {...{
      "id": "tablist-css-selectors"
    }}>{`TabList CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-tab-list] {
}
`}</code></pre>
    <h4 {...{
      "id": "tablist-props"
    }}>{`TabList Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tablist-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"div"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tablist-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tablist-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "tablist-children"
    }}>{`TabList children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`TabList`}</inlineCode>{` expects multiple `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` elements as children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabList>
  <Tab>One</Tab>
  <Tab>Two</Tab>
</TabList>
`}</code></pre>
    <p>{`But, you can also wrap `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` as long as you forward the props (because data is passed from `}<inlineCode parentName="p">{`TabList`}</inlineCode>{` to `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` via React context).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const RedTab = (props) => <Tab {...props} style={{ color: "red" }} />;

const TabPage = () => (
  <Tabs>
    <TabList>
      <RedTab>This is red</RedTab>
      <Tab>This is normal</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>...</TabPanel>
      <TabPanel>...</TabPanel>
    </TabPanels>
  </Tabs>
);
`}</code></pre>
    <h5 {...{
      "id": "tablist-as"
    }}>{`TabList as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`Tabs will render a `}<inlineCode parentName="p">{`div`}</inlineCode>{` unless you specify a different element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabList as={View} />
`}</code></pre>
    <h5 {...{
      "id": "tablist-div-props"
    }}>{`TabList div props`}</h5>
    <p>{`All other props are passed to the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` (or component passed to `}<inlineCode parentName="p">{`as`}</inlineCode>{`).`}</p>
    <h3 {...{
      "id": "tabpanels"
    }}>{`TabPanels`}</h3>
    <p>{`The parent component of the panels.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabPanels>
  <TabPanel>My favorite</TabPanel>
  <TabPanel>My other favorite</TabPanel>
</TabPanels>
`}</code></pre>
    <h4 {...{
      "id": "tabpanels-css-selectors"
    }}>{`TabPanels CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-tab-panels] {
}
`}</code></pre>
    <h4 {...{
      "id": "tabpanels-props"
    }}>{`TabPanels Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabpanels-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"div"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabpanels-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabpanels-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "tabpanels-as"
    }}>{`TabPanels as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`Tabs will render a `}<inlineCode parentName="p">{`div`}</inlineCode>{` unless you specify a different element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabPanels as={View} />
`}</code></pre>
    <h5 {...{
      "id": "tabpanels-children"
    }}>{`TabPanels children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`TabPanels`}</inlineCode>{` expects multiple `}<inlineCode parentName="p">{`TabPanel`}</inlineCode>{` elements as children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabPanels>
  <TabPanel>One</TabPanel>
  <TabPanel>Two</TabPanel>
</TabPanels>
`}</code></pre>
    <p>{`But, you can also wrap `}<inlineCode parentName="p">{`TabPanel`}</inlineCode>{` as long as you forward the props (because data is passed from `}<inlineCode parentName="p">{`TabPanels`}</inlineCode>{` to `}<inlineCode parentName="p">{`TabPanel`}</inlineCode>{` via React context\`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const BoldPanel = (props) => (
  <TabPanel {...props} style={{ fontWeight: "bold" }} />
);

const TabPage = () => (
  <Tabs>
    <TabList>
      <Tab>...</Tab>
      <Tab>...</Tab>
    </TabList>
    <TabPanels>
      <BoldPanel>...</BoldPanel>
      <TabPanel>...</TabPanel>
    </TabPanels>
  </Tabs>
);
`}</code></pre>
    <h5 {...{
      "id": "tabpanels-div-props"
    }}>{`TabPanels div props`}</h5>
    <p>{`All other props are passed to the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` (or component passed to `}<inlineCode parentName="p">{`as`}</inlineCode>{`).`}</p>
    <h3 {...{
      "id": "tab"
    }}>{`Tab`}</h3>
    <p>{`The interactive element that changes the selected panel.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Tab>Coconut Korma</Tab>
`}</code></pre>
    <h4 {...{
      "id": "tab-css-selectors"
    }}>{`Tab CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* styles all tabs */
[data-reach-tab] {
}

/* styles only the selected tab */
[data-reach-tab][data-selected] {
}
`}</code></pre>
    <h4 {...{
      "id": "tab-props"
    }}>{`Tab Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tab-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"button"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tab-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tab-disabled"
            }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tab-button-props"
            }}><inlineCode parentName="a">{`button`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "tab-as"
    }}>{`Tab as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`Tab will render a `}<inlineCode parentName="p">{`button`}</inlineCode>{` unless you specify a different element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Tab as={ReactNativeWebButton} />
`}</code></pre>
    <h5 {...{
      "id": "tab-children"
    }}>{`Tab children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`Tab`}</inlineCode>{` can receive any type of children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Tab>
  <HouseIcon /> Home
</Tab>
`}</code></pre>
    <h5 {...{
      "id": "tab-disabled"
    }}>{`Tab disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Disables a tab when true. Clicking will not work and keyboard navigation will skip over it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Tab disabled />
`}</code></pre>
    <h5 {...{
      "id": "tab-button-props"
    }}>{`Tab button props`}</h5>
    <p>{`All other props are passed to the underlying `}<inlineCode parentName="p">{`button`}</inlineCode>{` (or component passed to `}<inlineCode parentName="p">{`as`}</inlineCode>{`).`}</p>
    <h5 {...{
      "id": "tab-isselected"
    }}>{`Tab isSelected`}</h5>
    <p>{`Because TabList needs to know the order of the children, we use `}<inlineCode parentName="p">{`cloneElement`}</inlineCode>{` to pass state internally. If you want to know if a tab is active, you can wrap it, and then inspect clone props passed in.`}</p>
    <blockquote>
      <p parentName="blockquote">{`NOTE: We will deprecate this behavior in the future. We now prefer to use context to pass data down to descendants for `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{` for better composability. If you'd like your Tabs to be a bit more future proof, we suggest using a controlled `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{` component so that your app knows the state of your tabs.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function CoolTab(props) {
    // \`isSelected\` comes from \`TabList\` cloning the \`CoolTab\`.
    const { isSelected, children } = props;

    // make sure to forward *all* props received from TabList
    return (
      <Tab {...props}>
        {isSelected ? "😎" : "😐"}
        {children}
      </Tab>
    );
  }

  return (
    <Tabs>
      <TabList>
        <CoolTab>One</CoolTab>
        <CoolTab>Two</CoolTab>
      </TabList>
      <TabPanels>
        <TabPanel>1</TabPanel>
        <TabPanel>2</TabPanel>
      </TabPanels>
    </Tabs>
  );
})();
`}</code></pre>
    <h3 {...{
      "id": "tabpanel"
    }}>{`TabPanel`}</h3>
    <p>{`The panel that displays when it's corresponding tab is active.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabPanel>
  <h2>The Best Food</h2>
  <p>The best food is either Mexican or Indian.</p>
</TabPanel>
`}</code></pre>
    <h4 {...{
      "id": "tabpanel-css-selectors"
    }}>{`TabPanel CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* styles all tabs */
[data-reach-tab-panel] {
}
`}</code></pre>
    <h4 {...{
      "id": "tabpanel-props"
    }}>{`TabPanel Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabpanel-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"div"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabpanel-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tabpanel-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "tabpanel-as"
    }}>{`TabPanel as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`TabPanel will render a `}<inlineCode parentName="p">{`div`}</inlineCode>{` unless you specify a different element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Tab as={View} />
`}</code></pre>
    <h5 {...{
      "id": "tabpanel-children"
    }}>{`TabPanel children`}</h5>
    <p><inlineCode parentName="p">{`children?: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`TabPanel`}</inlineCode>{` can receive any type of children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TabPanel>
  <h2>Whatever you want</h2>
  <p>In here</p>
</TabPanel>
`}</code></pre>
    <h5 {...{
      "id": "tabpanel-div-props"
    }}>{`TabPanel div props`}</h5>
    <p>{`All other props are passed to the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{` (or component passed to `}<inlineCode parentName="p">{`as`}</inlineCode>{`).`}</p>
    <h3 {...{
      "id": "usetabscontext"
    }}>{`useTabsContext`}</h3>
    <p><inlineCode parentName="p">{`function useTabsContext(): { focusedIndex: number; id: string; selectedIndex: number }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{` component to its descendants.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function CustomTab({ index, ...props }) {
    const { selectedIndex, focusedIndex } = useTabsContext();
    return (
      <Tab
        style={{
          borderBottom: \`4px solid \${
            selectedIndex === index
              ? "red"
              : focusedIndex === index
              ? "blue"
              : "black"
          }\`,
        }}
        {...props}
      />
    );
  }

  return (
    <Tabs keyboardActivation="manual">
      <TabList>
        <CustomTab index={0}>Uno</CustomTab>
        <CustomTab index={1}>Dos</CustomTab>
        <CustomTab index={2}>Tres</CustomTab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <p>Uno</p>
        </TabPanel>
        <TabPanel>
          <p>Dos</p>
        </TabPanel>
        <TabPanel>
          <p>Tres</p>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
})();
`}</code></pre>
    <h2 {...{
      "id": "demos"
    }}>{`Demos`}</h2>
    <p>{`These demos show off how you can add quite a bit of behavior to your Tabs interfaces.`}</p>
    <h3 {...{
      "id": "data-driven-tabs"
    }}>{`Data-driven tabs`}</h3>
    <p>{`If you'd like to drive your tabs with data you can create a `}<inlineCode parentName="p">{`DataTabs`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function DataTabs({ data }) {
    return (
      <Tabs>
        <TabList>
          {data.map((tab, index) => (
            <Tab key={index}>{tab.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {data.map((tab, index) => (
            <TabPanel key={index}>{tab.content}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  }

  // now if you have an array of data...
  const tabData = [
    { label: "Taco", content: "Perhaps the greatest dish ever invented." },
    {
      label: "Burrito",
      content:
        "Perhaps the greatest dish ever invented but bigger and with rice.",
    },
  ];

  // you can just pass it in:
  return <DataTabs data={tabData} />;
})();
`}</code></pre>
    <h3 {...{
      "id": "animation"
    }}>{`Animation`}</h3>
    <p>{`With a little composition we can animate the selected tab bar.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  const HORIZONTAL_PADDING = 8;
  const AnimatedContext = React.createContext();

  function AnimatedTabs({ color, children, ...rest }) {
    // some state to store the position we want to animate to
    const [activeRect, setActiveRect] = React.useState(null);
    const ref = React.useRef();
    const rect = useRect(ref);

    return (
      // put the function to change the styles on context so an active Tab
      // can call it, then style it up
      <AnimatedContext.Provider value={setActiveRect}>
        {/* make sure to forward props since we're wrapping Tabs */}
        <Tabs
          {...rest}
          ref={ref}
          style={{ ...rest.style, position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              height: 2,
              background: color,
              transition: "all 300ms ease",
              left:
                (activeRect && activeRect.left) -
                (rect && rect.left) +
                HORIZONTAL_PADDING,
              top: (activeRect && activeRect.bottom) - (rect && rect.top),
              // subtract both sides of horizontal padding to center the div
              width: activeRect && activeRect.width - HORIZONTAL_PADDING * 2,
            }}
          />
          {children}
        </Tabs>
      </AnimatedContext.Provider>
    );
  }

  function AnimatedTab({ index, ...props }) {
    // get the currently selected index from useTabsContext
    const { selectedIndex } = useTabsContext();
    const isSelected = selectedIndex === index;

    // measure the size of our element, only listen to rect if active
    const ref = React.useRef();
    const rect = useRect(ref, { observe: isSelected });

    // get the style changing function from context
    const setActiveRect = useContext(AnimatedContext);

    // callup to set styles whenever we're active
    React.useLayoutEffect(() => {
      if (isSelected) {
        setActiveRect(rect);
      }
    }, [isSelected, rect, setActiveRect]);

    return (
      <Tab
        ref={ref}
        {...props}
        style={{
          ...props.style,
          border: "none",
          padding: \`4px \${HORIZONTAL_PADDING}px\`,
        }}
      />
    );
  }

  return (
    <AnimatedTabs color="red" style={{ width: 400 }}>
      <TabList style={{ justifyContent: "space-around" }}>
        <AnimatedTab index={0} style={{ flex: 1 }}>
          The First
        </AnimatedTab>
        <AnimatedTab index={1} style={{ flex: 2 }}>
          This has longer text
        </AnimatedTab>
        <AnimatedTab index={2} style={{ flex: 1 }}>
          Three
        </AnimatedTab>
      </TabList>
      <TabPanels style={{ padding: 10 }}>
        <TabPanel>
          <p>Check it out! It's ~animated~</p>
        </TabPanel>
        <TabPanel>
          <p>Yeah yeah. What's up?</p>
        </TabPanel>
        <TabPanel>
          <p>Oh, hello there.</p>
        </TabPanel>
      </TabPanels>
    </AnimatedTabs>
  );
})();
`}</code></pre>
    <h3 {...{
      "id": "keyboard-accessibility"
    }}>{`Keyboard Accessibility`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Enter`}</kbd>{` / `}<kbd>{`Spacebar`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sets the focused tab to `}<inlineCode parentName="td">{`active`}</inlineCode>{` when `}<inlineCode parentName="td">{`keyboardActivation`}</inlineCode>{` is set to `}<inlineCode parentName="td">{`"manual"`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`ArrowUp`}</kbd>{` / `}<kbd>{`ArrowDown`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigates between tabs when `}<inlineCode parentName="td">{`orientation`}</inlineCode>{` is `}<inlineCode parentName="td">{`"vertical"`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`ArrowLeft`}</kbd>{` / `}<kbd>{`ArrowRight`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigates between tabs when `}<inlineCode parentName="td">{`orientation`}</inlineCode>{` is `}<inlineCode parentName="td">{`"horizontal"`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Home`}</kbd>{` / `}<kbd>{`PageUp`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigates to the last tab in the `}<inlineCode parentName="td">{`TabList`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`End`}</kbd>{` / `}<kbd>{`PageDown`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigates to the first tab in the `}<inlineCode parentName="td">{`TabList`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      